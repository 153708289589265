import React from "react";
import {
  Box,
  Text,
  Link,
  Stack,
  Heading,
  Flex,
  VStack,
  List,
  ListItem,
} from "@chakra-ui/react";

import { GetNotice } from "../pages/api/base";
import { checkIEAccess } from "../provider/common";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notice: [],
    };
  }
  componentDidMount() {
    this.notice();
    checkIEAccess();
  }

  notice = async () => {
    const notice = await GetNotice();
    this.setState({ notice: notice });
  };

  render() {
    const { notice } = this.state;
    return (
      <Box className="maxist_body">
        <Box w="100%" color="#555" borderTop="1px" borderColor="#ededed">
          <Flex w="1000px" margin="0 auto">
            <Box flex="1" bg="" pt={10} pb={10}>
              <Heading as="h4" size="md" mb={5}>
                무엇을 도와드릴까요?
              </Heading>
              <Box fontSize="sd" className="footer-contact">
                <Box
                  className="footer-contact__text-box "
                  style={{ display: "flex" }}
                  m="2% 0"
                >
                  <Text flex="0.4">평일(월~목)</Text>
                  <Text flex="0.42">09:00 ~ 18:00</Text>
                  <Text flex="0.8"></Text>
                </Box>
                <Box
                  className="footer-contact__text-box "
                  style={{ display: "flex" }}
                  m="2% 0"
                >
                  <Text flex="0.4">평일(금요일) </Text>
                  <Text flex="0.42">09:00 ~ 17:00</Text>
                  <Text flex="0.8">(주말 및 공휴일 휴무)</Text>
                </Box>
                <Box
                  className="footer-contact__text-box "
                  style={{ display: "flex" }}
                  m="2% 0"
                >
                  <Text flex="0.4">점심시간 </Text>
                  <Text flex="0.42">12:30 ~ 13:30</Text>
                  <Text flex="0.8"></Text>
                </Box>
              </Box>
            </Box>

            <Box flex="1" bg="" pt={10} pb={10}>
              <Heading as="h4" size="md" mb={5}>
                전자부품 판다파츠
              </Heading>
              <Box fontSize="md" className="footer-contact">
                <VStack spacing={4} align="stretch">
                  <Link
                    href="http://pf.kakao.com/_uKLiT/chat"
                    target="_blank"
                    rel=""
                  >
                    <Box color="red.500" cursor="pointer">
                      채팅 상담하기(카카오톡)
                    </Box>
                  </Link>
                  <Box>
                    <Link href="/board/qna">문의글 남기기</Link>
                  </Box>
                  <Box>
                    <Link href="mailto:support@partsgo.co.kr">
                      이메일 문의하기
                    </Link>
                  </Box>
                  <Box>전화문의 (070-4742-4989)</Box>
                </VStack>
              </Box>
            </Box>
            <Box flex="1" bg="" pt={10} pb={10}>
              <Heading as="h4" size="md" mb={5}>
                공지사항
              </Heading>
              <List>
                {notice.length > 0
                  ? notice.map((e) => (
                      <Link href="/board/notice" key={e.wr_id}>
                        <ListItem
                          marginTop="5px"
                          className="footer-notice"
                          key={e.wr_id}
                        >
                          {e.wr_subject}
                        </ListItem>
                      </Link>
                    ))
                  : ""}
              </List>
              <Text fontSize="md"></Text>
            </Box>
          </Flex>

          <Box
            bg=""
            borderTop="1px"
            borderColor="#ededed"
            w="100%"
            p={0}
            mb={5}
            color="#a3a5ad"
            fontSize={12}
            letterSpacing={-0.5}
          >
            <Stack
              direction="row"
              spacing={5}
              align="center"
              mt="5"
              mb="5"
              w="1000px"
              margin="0 auto"
            >
              <Link href="/board/fast?status=이용약관">
                <Text colorScheme="gray" variant="ghost" p="2">
                  이용약관
                </Text>
              </Link>
              <Link href="/board/fast?status=개인정보취급방침">
                <Text colorScheme="gray" variant="ghost" p="2">
                  개인정보처리방침
                </Text>
              </Link>
              <Link
                href="https://www.ftc.go.kr/www/bizCommList.do?key=232"
                target="_blank"
              >
                <Text colorScheme="gray" variant="ghost" p="2">
                  사업자정보확인
                </Text>
              </Link>
              <Link
                href="https://mark.inicis.com/mark/escrow_popup.php?mid=pandapart1"
                target="_blank"
                rel="noopener"
              >
                <Text colorScheme="gray" variant="ghost" p="2">
                  에스크로가입확인
                </Text>
              </Link>
              <Link
                href="https://www.ilogen.com/web/reservation/returns"
                target="_blank"
                rel="noopener"
              >
                <Text colorScheme="gray" variant="ghost" p="2">
                  AS배송예약
                </Text>
              </Link>
            </Stack>
            <Text w="1000px" margin="0 auto" paddingTop="7">
              파츠고(주) 대표이사 : 이종철 | 서울특별시 강남구 테헤란로82길 15
              (대치동 942-15) 디아이타워 5층 사업자등록번호 : 557-81-00960 |
              통신판매업 신고번호 : 2021-서울강남-06663호 <br></br> 상표권
              등록번호 : 제40-1705579호 | 부가통신사업 신고번호 :
              제2-01-19-0130호 | 호스팅서비스 제공자 : 파츠고 주식회사<br></br>
              Copyright © 2018 PARTSGO. All rights reserved.
            </Text>
            <Text w="1000px" margin="0 auto" textAlign="center" paddingTop={15}>
              <br></br> 판다파츠는 각 제조사, 유통사, 대리점등(이하 회사)에서
              제공하는 전자부품의 정보, 가격을 비교, 중개, 배송, 통관 대행을
              하는 플랫폼 서비스로
              <br /> 구매, 배송, 반품, 취소, AS등에 대한 정책은 각 회사에 정책에
              따릅니다.{" "}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
}
